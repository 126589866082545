import React from "react";
import Container from "../container/Container";
import InfiAnimation from "../../../extra/infiAnimation/index";

const HiringPartner = ({ uni }) => {
  return (
    <div  className= {` ${uni.Color} mb-[5rem]`} >
      
      <h1 className="text-center  text-white pt-6 leading-4 md:text-[25px] text-[20px] font-semibold capitalize">

      the top hiring partners of </h1> 
      <h1 className="md:text-[40px] text-[40px] text-center ">{uni ? uni.university : "Internal Server Error"}</h1>
     
      {/* <h4 className="text-center pb-10 text-[20px] font-semibold capitalize">
       
      </h4> */}
      {/* <h1 className='text-center text-[20px]'>Slider</h1> */}
      <InfiAnimation uni={uni} />
    </div>
  );
};

export default HiringPartner;
