import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import UniversityPage from "./screen/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { FaArrowRight, FaCalendarAlt } from "react-icons/fa";
import im1 from "./screen/home/courses/image/Courses/1.png";
import im2 from "./screen/home/courses/image/Courses/2.png";
import im3 from "./screen/home/courses/image/Courses/3.png";
import im4 from "./screen/home/courses/image/Courses/7.png";
import im5 from "./screen/home/courses/image/Courses/8.png";
import im6 from "./screen/home/courses/image/Courses/9.png";
import im7 from "./screen/home/courses/image/Courses/10.png";
import im8 from "./screen/home/courses/image/Courses/11.png";

import uiux from "./images/qunatumUniversity/quantumEvents/uiux.jpg";
import education from "./images/qunatumUniversity/quantumEvents/education.jpg";
import discipline from "./images/qunatumUniversity/quantumEvents/discipline.jpg";
import digitalmarketing from "./images/qunatumUniversity/quantumEvents/digitalmarketing.jpg";
import webdev from "./images/parulUniversity/event/webdev.jpg";
import analyis from "./images/parulUniversity/event/analyis.jpg";
import entrepreneur from "./images/parulUniversity/event/entrepreneur.jpg";
import pacific from "./images/pacificUniversity/pacific.jpg";
// import llm from "./images/pacificUniversity/courses/llm.jpg";
// import bscnursing from "./images/pacificUniversity/courses/bscnursing.jpg";
// import mscnursing from "./images/pacificUniversity/courses/mscnursing.jpg";
import pacifich1 from "./images/pacificUniversity/hiringPartner/1.jpg";
import pacifich2 from "./images/pacificUniversity/hiringPartner/2.jpg";
import pacifich3 from "./images/pacificUniversity/hiringPartner/3.jpg";
import pacifich4 from "./images/pacificUniversity/hiringPartner/4.jpg";
import pacifich5 from "./images/pacificUniversity/hiringPartner/5.jpg";
import pacifich6 from "./images/pacificUniversity/hiringPartner/6.jpg";
import pacifich7 from "./images/pacificUniversity/hiringPartner/7.jpg";
import pacifich8 from "./images/pacificUniversity/hiringPartner/8.jpg";
import pacifich9 from "./images/pacificUniversity/hiringPartner/9.jpg";
import pacifich10 from "./images/pacificUniversity/hiringPartner/10.jpg";
import parmatmasingh from "./images/pacificUniversity/placedStudent/2.png";
import vinaysinghbandral from "./images/pacificUniversity/placedStudent/3.png";
import vikrantbaloria from "./images/pacificUniversity/placedStudent/4.png";
import swapnilsingh from "./images/pacificUniversity/placedStudent/5.png";
import Navbar from "./screen/home/navbar";
import Enquriyform from "./common/enquiryform/page";
import Footer from "./screen/home/Footer/Footer";
import Disclaimer from "./disclaimer";
import pacific2 from "./images/pacificUniversity/pacific2.jpeg";
import pacific3 from "./images/pacificUniversity/pacific3.jpg";

import BlogPost1 from "./screen/blog/blog1";
import BlogPost2 from "./screen/blog/blog2";
import PrivacyPolicy from "./screen/privacyPolicy";
const App = () => {
  // const uni = {
  //   id: "karnavati-university",
  //   textColor:"#EA2284",
  //   university: "pacific University",
  //   image: [karnavati2,karnavati4],
  //   courses: [
  //     {
  //       name: "BBA(Hons)",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         "Marketing",
  //         "Finance",
  //         "HR Management",
  //         "Digital Marketing",
  //         "Aviation",
  //         "Sports Management",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im1, // image source for BBA
  //       description: "Description for BBA course goes here.",
  //     },
  //     {
  //       name: "MBA.",
  //       fee: "1,77,500/Year",
  //       details: ["2 Years", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "Available based on merit and research grants.",
  //       specialisation: [
  //         "Marketing",
  //         "Finance",
  //         "Human Resource (HR)",
  //         "Entrepreneurship And Family Business",
  //         " Data Analytics & Business Intelligence",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im2, // image source for Ph.D.
  //       description: "Description for MBA program goes here.",
  //     },
  //     {
  //       name: "BA LLB (Hons.)",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         " Business Law",
  //         " IPL (Intellectual Property law)",
  //         "International Law",
  //         "Criminial Law",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im3, // image source for LLM
  //       description: "Description for LLM course goes here.",
  //     },
  //     {
  //       name: "BA (Hons )‐LA",
  //       fee: "2,20,000/Year",
  //       details: ["3+1 Years", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "Available based on merit and financial need.",
  //       specialisation: [
  //         "Political Science & Public Affairs",
  //         "Event Management",
  //         " Accounting and Economics",
  //         "Psychology",
  //         " Journalism",
  //         "Ad & PR",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im8, // image source for BA
  //       description: "Description for BA program goes here.",
  //     },
  //     {
  //       name: "BBA LLB(Hons.)",
  //       fee: "₹ 2,60,000/year",
  //       details: ["5 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         " Business Law",
  //         "Criminal Law",
  //         " IPL (Intellectual Property law)",
  //         "International Law",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im1, // image source for BBA LLB
  //       description: "Description for BBA LLB course goes here.",
  //     },
  //     {
  //       name: "B.Tech",
  //       fee: "₹ 1,39,250/year",
  //       details: ["4 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         " Computer Science & Engineering",
  //         " CSE -AI & ML",
  //         "CSE- Cyber Security",
  //         " CSE-Data Science",
  //         " Information & Communication Technology",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im7, // image source for B.Tech
  //       description: "Description for B.Tech course goes here.",
  //     },
  //     {
  //       name: "B.S. (Hons) Computer Science",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus","Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         "Computer Science",
  //         "Data Science",
  //         " Artificial Intelligence & Machine Learning",
  //         "Forensic Science",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im8, // image source for M.Tech
  //       description: "Description for   B.S(Hons) course goes here.",
  //     },
  //     {
  //       name: "BCA",
  //       fee: "₹ 80,000/year",
  //       details: ["3 Year", "On-campus", "Full Time"],
  //       rating: 4.8,
  //       scholarship: "According to merit and need-based.",
  //       specialisation: [
  //         "Computer Applications",
  //         "Software Development",
  //         "Web Development",
  //         "Networking",
  //         "Database Management",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im4, // image source for BCA
  //       description: "Description for BCA course goes here.",
  //     },
  //     {
  //       name: "Integrated‐ B.S. +M.S. Cyber Security",
  //       fee: "₹ 2,20,000/year",
  //       details: ["5 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship:
  //         "According to entrance exam results and academic performance.",
  //       specialisation: [
  //         "Political Science & Public Affairs",
  //         "Event Management",
  //         " Accounting and Economics",
  //         "Psychology",
  //         " Journalism",
  //         "Ad & PR",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im5, // image source for MCA
  //       description: "Description for B.S+M.S course goes here.",
  //     },
  //     {
  //       name: "B.Com (Hons)‐LA ",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship:
  //         "According to entrance exam results and academic performance.",
  //       specialisation: ["Cyber Security"],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im8, // image source for MCA
  //       description: "Description for B.S+M.S course goes here.",
  //     },
  //   ],
  //   event: [
  //     {
  //       img: award1,
  //       data: "31",
  //       month: "may, 2023",
  //       hed: "Karnavati University felicitated with EducationWorld Award",
  //       pre: "We are elated to announce that Karnavati University has won the EducationWorld award under the category ‘Campus Design Excellence!",
  //       time: "May 31, 2023",
  //       country: "United Kingdom",
  //       alt: "Augmented Reality Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award2,
  //       data: "25",
  //       month: "jan, 2023",
  //       hed: "4 Star Ranking by Institution’s Innovation Council (IIC)",
  //       pre: "We are happy to announce that Karnavati University has achieved 4 Star Ranking for its continuous efforts to promote Innovation",
  //       time: "January 25, 2023",
  //       country: "United States",
  //       alt: "UX Research Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award4,
  //       data: "21",
  //       month: "May, 2022",
  //       hed: "Top Design Institute of India by Competition Success Review (CSR)",
  //       pre: "Unitedworld Institute of Design, Karnavati University, Gandhinagar, Gujarat has been awarded the CSR Top Design Institute of India by the Chairman of the acclaimed Competition Success Review (CSR), Mr. SK Sachdeva.",
  //       time: "May 21, 2022",
  //       country: "Canada",
  //       alt: "Game Development Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award6,
  //       data: "13",
  //       month: "may , 2019",
  //       hed: "Excellence in Education Award",
  //       pre: "Shri Ritesh Hada, President, Karnavati University, was felicitated by Shri Vijay Rupani, Hon’ble Chief Minister of Gujarat, for extraordinarily exceptional work done",
  //       time: "13 may,2019",
  //       country: "Australia",
  //       alt: "Cloud Security Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award5,
  //       data: "27",
  //       month: "aprail 2019",
  //       hed: "UWSB ranks 24th (A++ Category)",
  //       pre: "Unitedworld School of Business (UWSB) – Ahmedabad Campus ranked 24th (A++ Category) 8th (Industry Interface) 10th (RoI) 10th (West Zone) 10th (Infrastructure) in the annual survey of Siliconindia Education What if not IIMs: B- Schools Survey 2017",
  //       time: "April 27, 2019",
  //       country: "Germany",
  //       alt: "Social Media Marketing Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award3,
  //       data: "2",
  //       month: "may 2019",
  //       hed: "UID – Best Private Design College in India",
  //       pre: "Unitedworld Institute of Design (UID) has been conferred with the award “Best Private Design College in India” during the National Education Excellence Awards organised by one of the leading Brand Management Consulting & Research firm in India, World Business...",
  //       time: "2 May 2019",
  //       country: "France",
  //       alt: "img",
  //       arrow: <FaArrowRight />,
  //     },
  //   ],
  //   ourPlacedStudent: [
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "I cannot forget my days at Quantum and can’t thank them enough for the wonderful friends that I have made there.",
  //       perImg: qankit,
  //       Name: "Ankit",
  //       work: "Publicis sapient",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "Thanks to the technical skills I got at Quantum, I am today working in one of the top IT companies in India.",
  //       perImg: qarchit,
  //       Name: "Archit Madan",
  //       work: "Adobe",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         " Quantum has not only given me education but has also helped me to build my personality, which helped me to shape my career.",
  //       perImg: qbhupendra,
  //       Name: "Bhupender Pal ",
  //       work: "Oracle",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "I number of companies that visit Quantum for placements is simply amazing. All the students get ample chance to get placed.",
  //       perImg: qdipayan,
  //       Name: "Dipayan Bhowmik",
  //       work: "Adobe",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "Being at Quantum was one of the greatest experiences of my life. I learnt a lot of new things to grow my career.   ",
  //       perImg: qkartikey,
  //       Name: "Karthikey Chauhan",
  //       work: "Capgemini",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "It was an Overwhelming experience. I still miss the moments I spent at Quantum. I want to study once more at Quantum. ",
  //       perImg: qmayank,
  //       Name: "Mayank Tayal",
  //       work: "NIIT Technologies",
  //     },
  //   ],
  //   applicationDeadline: "1 july",
  //   noScholarships: "22",
  //   amount: "variable",
  //   internationalStudents: "yes",
  //   scholarshipLink: "www.aapadhe.in",
  //   address: "Gujarat",
  //   loanBNK:
  //     "QUANTUM UNIVERSITY IS UGC APPROVED UNIVERSITY, SO ONCE YOU RECEIVE YOUR JOINING LETTER,YOU CAN CONTACT YOUR BANK FOR EDUCATION LOANS. FOR MORE",
  //   email: "karnawati@offical.com",
  //   phone: "+919257767661",
  //   hiringPartner: [
  //     karah1,
  //     karah2,
  //     karah3,
  //     karah4,
  //     karah5,
  //     karah6,
  //     karah7,
  //     karah8,
  //     karah9,
  //     karah10,
  //     karah11,
  //     karah12,
  //     karah4,
  //     karah7,
  //   ],
  //   studentEnroll: "125,300",
  //   registeredInstructor: "300",
  //   successRate: "100",
  //   successStory: [
  //     "https://www.youtube.com/embed/5DGP_NhSMkM?si=_JQ3p_UBNYHT2I8W",
  //     "https://www.youtube.com/embed/shu-kJkwKUk?si=LeZ3F-ppgBI8EP3d",
  //     "https://www.youtube.com/embed/Uquu6fNya9Q?si=2G5SBuASTo_FYxTm",
  //     "https://www.youtube.com/embed/LKaEQ1-KZ58?si=FiV_gsTmE0pKiu5C",
  //     "https://www.youtube.com/embed/5DGP_NhSMkM?si=_JQ3p_UBNYHT2I8W",
  //     "https://www.youtube.com/embed/LKaEQ1-KZ58?si=FiV_gsTmE0pKiu5C",
  //     "https://www.youtube.com/embed/Uquu6fNya9Q?si=2G5SBuASTo_FYxTm",
  //     "https://www.youtube.com/embed/5DGP_NhSMkM?si=_JQ3p_UBNYHT2I8W",
  //   ],
  //   doubtSection: [
  //     {
  //       question: "Is Quantum University good?",
  //       answer1:
  //         "It has featured among the top 10 engineering colleges in uttarakhand. Over the past few years, Quantum University has won the title of best placement college in dehradun,",
  //       answer2:
  //         "due to its latest and innovative teaching-learning methodology. Several reputed companies visit the campus every year for recruiting Q-mates.",
  //     },
  //     {
  //       question: "Is Quantum University degree valid?",
  //       answer1:
  //         "Yes, the degree offered by Quantum University is valid. Quantum University is recognised by the University Grants Commission, which is a statutory body of the Government of India.",
  //     },
  //     {
  //       question: "How old is quantum university?",
  //       answer1:
  //         "History. Quantum University Roorkee was founded in 2017 by industrialist Shri Shyam Sunder Goyal. The core programs initially offered by the college were in the disciplines of Engineering and Management. In 2018 the university started programs in Graduate Studies.",
  //     },
  //     {
  //       question: "Is quantum is UGC approved?",
  //       answer1:
  //         "Quantum University is a private university in Roorkee, Uttarakhand, India. It was established in 2008 and is approved by the University Grants Commission which is a statutory body of the Government of India. Quantum University has both government recognition and professional accreditation.",
  //     },
  //     {
  //       question: "Is Quantum University good for placement?",
  //       answer1:
  //         "Placements: Yes, arrange for placement, and more than 100 students got placed this year alone. A total of 900 students received placement offers from Quantum University in various companies and MNCs.",
  //     },
  //   ],
  //   blogNews: [
  //     {
  //       img: marwadie1,
  //       data: "6",
  //       icon: <FaCalendarAlt />,
  //       month: "6th November 2024",
  //       hed: "Internship Workshop.",
  //       pre: "InternEdge Internship Workshop for Students of Karnawati University",
  //       txt: "Read More",
  //       country: "Belgium",
  //       alt: "Student Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: marwadie2,
  //       data: "30",
  //       icon: <FaCalendarAlt />,
  //       month: "30th December 2024",
  //       hed: "Job Fair.",
  //       pre: "CareerPro Job Fair for Students of Karnawati University",
  //       txt: "Read More",
  //       country: "Austria",
  //       alt: "Student Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: marwadie1,
  //       data: "10",
  //       icon: <FaCalendarAlt />,
  //       month: "10th January 2025",
  //       hed: "Career Development Workshop.",
  //       pre: "FutureCare Career Development Workshop for Students of Karnawati University",
  //       txt: "Read More",
  //       country: "Norway",
  //       alt: "Student Image",
  //       arrow: <FaArrowRight />,
  //     },
  //   ],
  // };
  const uni = {
    id: "pacific-university",
    Color: "bg-[#4F6ECC]",
    text: "[#4F6ECC]",
    university: "Pacific University",
    image: [pacific, pacific, pacific],
    courses: [
      {
        name: "LLM",
        fee: "₹ 50,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Corporate Law",
          "Criminal Law",
          "Constitutional Law",
          "International Law",
          "Family Law",
        ],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im3, // image source for LLM
        description: "Description for LLM course goes here.",
      },
      {
        name: "M.Sc",
        fee: "₹ 25,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.8,
        scholarship: "Merit-based scholarships available.",
        specialisation: [
          "chemistry",
          "Mathmatics",
          "Physics",
          "Botany",
          "Zoology",
        ],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im4, // image source for M.Sc. Nursing
        description: "Description for M.Sc. course goes here.",
      },
      {
        name: "B.Sc",
        fee: "₹ 25,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.8,
        scholarship: "Merit-based scholarships available.",
        specialisation: ["PCM,PCB,agriculture"],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im8, // image source for B.Sc. Nursing
        description: "Description for B.Sc. Nursing course goes here.",
      },
      {
        name: "BBA",
        fee: "₹ 50,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Business Analytics",
          "Financial Markets",
          "Digital Marketing",
          "Business Analytics",
        ],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im1, // image source for BBA LLB
        description: "Description for BBA  course goes here.",
      },
      {
        name: "MBA",
        fee: "₹ 85,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: ["Business Analytics"],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im2, // image source for BBA LLB
        description: "Description for BBA LLB course goes here.",
      },
      {
        name: "B LIB",
        fee: "₹ 30,000/year",
        details: ["1 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Computer Science",
          "Electrical Engineering",
          "Mechanical Engineering",
          "Civil Engineering",
          "Electronics and Communication",
        ],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im4, // image source for B.Tech
        description: "Description for B.Tech course goes here.",
      },
      {
        name: "B.Tech",
        fee: "₹ 60,000/year",
        details: ["4 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Computer Science",
          "Electrical Engineering",
          "Mechanical Engineering",
          "Civil Engineering",
          "Electronics and Communication",
        ],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im6, // image source for M.Tech
        description: "Description for M.Tech course goes here.",
      },
      {
        name: "B.Tech(AI)",
        fee: "₹ 95,000/year",
        details: ["4 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Artificial Intelligence & Machine Learning in Collaboration with Upgrad",
        ],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im5, // image source for M.Tech
        description: "Description for M.Tech course goes here.",
      },
      {
        name: "M.Tech",
        fee: "₹ 80,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Computer Science",
          "Electrical Engineering",
          "Mechanical Engineering",
          "Civil Engineering",
          "Electronics and Communication",
        ],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im6, // image source for M.Tech
        description: "Description for M.Tech course goes here.",
      },
      {
        name: "BCA",
        fee: "₹ 40,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.8,
        scholarship: "According to merit and need-based.",
        specialisation: ["GENERAL"],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im5, // image source for BCA
        description: "Description for BCA course goes here.",
      },
      {
        name: "MCA",
        fee: "₹ 50,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: ["GENERAL"],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im7, // image source for MCA
        description: "Description for MCA course goes here.",
      },
      {
        name: "B.Com(Hons)",
        fee: "₹ 40,000/year",
        details: ["4 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Business Analytics",
          "Financial Markets",
          "Digital Marketing",
          "Business Analytics",
        ],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im4, // image source for BBA LLB
        description: "Description for B.Com  course goes here.",
      },
      {
        name: "M.Com",
        fee: "₹ 25,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Business Analytics",
          "Financial Markets",
          "Digital Marketing",
          "Business Analytics",
        ],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im6, // image source for BBA LLB
        description: "Description for M.Com  course goes here.",
      },
      {
        name: "B.Sc in Hotel Management",
        fee: "₹ 70,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: ["hotel management"],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im6, // image source for BBA LLB
        description: "Description for Hotel management  course goes here.",
      },
      {
        name: "MTHM",
        fee: "₹ 65,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: ["hotel management"],
        location: "Udaipur, Rajasthan",
        approval: true,
        image: im6, // image source for BBA LLB
        description: "Description for MTHM  course goes here.",
      },
    ],
    event: [
      {
        img: uiux,
        data: "22",
        month: "October, 2023",
        hed: "Cybersecurity Conference",
        pre: "Stay updated on the latest trends and threats in cybersecurity",
        time: "11:00 am - 6:00 pm",
        country: "United Kingdom",
        alt: "Cybersecurity Image",
        arrow: <FaArrowRight />,
      },
      {
        img: webdev,
        data: "5",
        month: "November, 2023",
        hed: "Mobile App Development Workshop",
        pre: "Learn to develop mobile apps for iOS and Android platforms",
        time: "1:30 pm - 5:30 pm",
        country: "United States",
        alt: "Mobile App Development Image",
        arrow: <FaArrowRight />,
      },
      {
        img: digitalmarketing,
        data: "18",
        month: "December, 2023",
        hed: "Data Science Symposium",
        pre: "Explore the latest advancements and applications in data science",
        time: "9:00 am - 7:00 pm",
        country: "Canada",
        alt: "Data Science Image",
        arrow: <FaArrowRight />,
      },
      {
        img: entrepreneur,
        data: "7",
        month: "January, 2024",
        hed: "Product Management Workshop",
        pre: "Gain insights into product management strategies and best practices",
        time: "10:00 am - 4:00 pm",
        country: "Australia",
        alt: "Product Management Image",
        arrow: <FaArrowRight />,
      },
      {
        img: analyis,
        data: "14",
        month: "February, 2024",
        hed: "Cloud Computing Summit",
        pre: "Explore the latest trends and innovations in cloud computing",
        time: "8:00 am - 6:00 pm",
        country: "Germany",
        alt: "Cloud Computing Image",
        arrow: <FaArrowRight />,
      },
      {
        img: uiux,
        data: "9",
        month: "March, 2024",
        hed: "UX/UI Design Conference",
        pre: "Discover the principles and best practices of user experience and user interface design",
        time: "10:00 am - 4:00 pm",
        country: "France",
        alt: "UX/UI Design Image",
        arrow: <FaArrowRight />,
      },
    ],
    ourPlacedStudent: [
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "Pacific University provided me with excellent technical education and ample career opportunities, leading to my successful placement in a reputable company.",
        perImg: parmatmasingh,
        Name: "Parmatma Singh",
        post: "Data Engineer",
        work: "New Zealand",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "Pacific University equipped me with top-notch skills and connections, securing a prestigious placement for me.",
        perImg: vinaysinghbandral,
        Name: "Vinay Singh Bandral",
        post: "Former Sr. Supply Chain Manager",
        work: "New Zealand",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "Pacific University's supportive environment and strong industry ties paved the way for my successful placement.",
        perImg: vikrantbaloria,
        Name: "Vikrant Baloria",
        work: "New Zealand",
        post: "Sr. Cloud Technical Manager",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "At Pacific University, I received quality education and valuable guidance that led to my placement in a prominent company.",
        perImg: swapnilsingh,
        Name: "Swapnil Singh",
        work: "New Zealand",
        post: "Former Package Solution Enablement Specialist",
      },
    ],
    applicationDeadline: "1 july",
    noScholarships: "25",
    amount: "variable",
    internationalStudents: "yes",
    scholarshipLink: "www.aaopadhe.in",
    loanBNK:
      "Pacific University HAS COLLABORATED WITH AXIS BANK TO FACILITATE ITS STUDENTS WHO ARE INTERESTED IN AVAILING EDUCATION LOANS.",
    email: "pacific@offical.com",
    phone: "+919610398895",
    hiringPartner: [
      pacifich1,
      pacifich2,
      pacifich3,
      pacifich4,
      pacifich5,
      pacifich6,
      pacifich7,
      pacifich8,
      pacifich9,
      pacifich10,
      pacifich2,
      pacifich4,
      pacifich4,
      pacifich8,
      pacifich10,
    ],
    studentEnroll: "125,300",
    registeredInstructor: "300",
    successRate: "100",
    address: "Udaipur, Rajasthan",
    successStory: [
      "https://www.youtube.com/embed/YSZw30XpNPw?si=B2qrtRTSFD_jpCWk",
      "https://www.youtube.com/embed/wljUZlQ-O64?si=AFSpSVd8C4HLNSU6",
      "https://www.youtube.com/embed/M3lGdVie-p8?si=M7i-kdjWhElrOPN8",
      "https://www.youtube.com/embed/E9vTz1blw3E?si=_GbmK3r7WDg1HahB",
      "https://www.youtube.com/embed/YSZw30XpNPw?si=B2qrtRTSFD_jpCWk",
      "https://www.youtube.com/embed/wljUZlQ-O64?si=AFSpSVd8C4HLNSU6",
      "https://www.youtube.com/embed/M3lGdVie-p8?si=M7i-kdjWhElrOPN8",
      "https://www.youtube.com/embed/E9vTz1blw3E?si=_GbmK3r7WDg1HahB",
    ],
    doubtSection: [
      {
        question: "Best Commerce College in Udaipur, Rajasthan",
        answer1:
          "Pacific university ( paher university ) is the best college for commerce students ",
        answer2: null,
      },
      {
        question: "What is the highest package offered in Pacific University?",
        answer1:
          "In 2023 almost 200 corporates visited the pacific university campus and offered packages minimum of 4 lacs & highest package was offered 33 lacs.",
        answer2: null,
      },
      {
        question:
          "What are the placements for Pacific Institute of Technology Udaipur?",
        answer1:
          "Total of 86 percent students got placement from the campus itself and other students are working their different goals",
        answer2: null,
      },
      {
        question: "Is Pacific University UGC approved?",
        answer1:
          "Pacific University is a private university in Udaipur. It was established in 2008 and is approved by the University Grants Commission, which is a statutory body of the Government of India. Pacific University has both government recognition and professional accreditation.",
        answer2: null,
      },
      {
        question: "How can I get admission in Pacific University Udaipur?",
        answer1:
          "To take admission in the university a candidate can either download the application form from the University website or can buy the form from the corporate office in the corresponding session or you can apply online",
        answer2: null,
      },
    ],
    blogNews: [
      {
        img: webdev,
        data: "14",
        icon: <FaCalendarAlt />,
        month: "14th May 2024",
        hed: "Career Expo.",
        pre: "TechWise Career Expo for Students of Pacific University",
        txt: "Read More",
        country: "Germany",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: education,
        data: "22",
        icon: <FaCalendarAlt />,
        month: "22nd June 2024",
        hed: "Interview Workshop.",
        pre: "FutureEdge Interview Workshop for Students of Pacific University",
        txt: "Read More",
        country: "France",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: discipline,
        data: "19",
        icon: <FaCalendarAlt />,
        month: "19th July 2024",
        hed: "Job Symposium.",
        pre: "CareerCraft Job Symposium for Students of Pacific University",
        txt: "Read More",
        country: "Spain",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
    ],
  };
  return (
    <div className="overflow-hidden">
      <Router>
        {/* <TopBar></TopBar> */}
        <Navbar uni={uni}></Navbar>
        <Routes>
          {/* Route for the home page */}
          <Route path="/" element={<UniversityPage uni={uni} />} />
          <Route path="/courses" element={<UniversityPage uni={uni} />} />
          <Route path="/apply-now" element={<UniversityPage uni={uni} />} />
          <Route path="/scholarship" element={<UniversityPage uni={uni} />} />
          {/* Route for the enquiry form */}
          <Route path="/enquiryform" element={<Enquriyform />} />
          <Route path="/blogpost2" element={<BlogPost2 />} />
          <Route path="/blogpost1" element={<BlogPost1 />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer uni={uni} />
        <Disclaimer />

        <div className="fixed bottom-0 text-[30px] right-0 gap-3 px-8 py-6 z-[9999]">
          <div className="p-3 bg-gray-300 mb-3 rounded-full">
            <a href="tel:+919610398895" >
              <IoCall className="text-blue-500" />
            </a>
          </div>
          <div className="p-3 bg-gray-300 mb-2 rounded-full">
            <a href="https://wa.me/+919610398895">
              <IoLogoWhatsapp className="text-green-500" />
            </a>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
