import React, { useState, useEffect, useContext } from "react";
import { TiTick } from "react-icons/ti";
import AnimatedText from "../../../extra/animation-charctor/index";
import Enquriyform from "../../../common/enquiryform/page";
import { Link } from "react-router-dom";

const Courses = ({ uni }) => {
  const [coursesdata, setCoursesData] = useState(null); // Initialize coursesdata as null initially
  const [alldata, setAlldata] = useState([]);
  const [datanum, setdatanum] = useState(3);

  // const navigateToEnquiryForm = () => {
  //   window.location.href = "/enquiryform";
  // };

  // smooth scrolling ke liye
  const handleClick = () => {
    window.scrollTo({
      top: 0, // Yahan aap apne desired scroll position ko specify kar sakte hain
      behavior: "smooth", // Smooth scrolling ke liye
    });
  };

  const increNum = () => {
    setdatanum(datanum + 3);
  };

  useEffect(() => {
    // Check if uni is not null and uni.courses exists
    if (uni && uni.courses) {
      // Set coursesdata to uni.courses
      setCoursesData(uni.courses);
    }
  }, [uni]);

  useEffect(() => {
    if (coursesdata) {
      setAlldata(coursesdata);
    }
  }, [coursesdata]);

  // bg-[#f2f2f2]

  return (
    <div className="w-full px-2 lg:px-0 bg-white py-5 pt-12">
      <div className="container mx-auto flex flex-col p-2 items-center rounded-lg ">
        <h1 className="text-center text-2xl font-bold text-black  py-4">
          Explore Pacific{" "}
          <span className={`md:inline text-${uni.text}`}> University Courses</span>
        </h1>
        {alldata.slice(0, datanum).map((course, index) => (
          <div
            key={index}
            className="w-full md:w-[98%] flex justify-between flex-wrap mb-4 bg-white shadow-xl ">
            <div className="  md:flex">
              <div className="object-cover rounded-t-lg flex justify-center w-[100%] md:w-48  md:h-60 h-48">
                <img src={course.image} alt="" />
              </div>
              <div className="flex justify-start items-center p-2 md:p-4 leading-normal">
                <div className="">
                  <div className="flex justify-between ">
                    <h2 className="mb-2 text-sm font-bold tracking-tight shadow-xl rounded-md px-2 py-2">
                      {course.name}
                    </h2>
                  </div>
                  <div className="mb-2 text-sm font-[600] flex gap-4 tracking-tight flex-wrap">
                    <div className="">Course Details</div>
                    <div className="flex items-center text-sm text-green-600 2 gap-2">
                      {course.details.map((detail, i) => (
                        <p key={i}>{detail}</p>
                      ))}
                    </div>
                  </div>
                  <div className="flex items-center">
                    {/* Ratings */}
                    <div className="flex items-center">
                      {[...Array(5)].map((_, i) =>(
                        <svg
                          key={i}
                          className={`w-4 h-4 text-${
                            i < course.rating ? "yellow-300" : "gray-300"
                          } me-1`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 22 20">
                          {/* SVG Path */}
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                        </svg>
                      ))}
                    </div>
                    <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">
                      {course.rating}
                    </p>
                    <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">
                      out of
                    </p>
                    <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">
                      5
                    </p>
                  </div>
                  {/* Other details */}
                  {/* Scholarship */}
                  <div className="mb-2 text-sm font-[600] flex gap-4 tracking-tight">
                    <div className="">Scholarship :</div>
                    <div className="flex items-center text-sm text-green-600 2 gap-2">
                      <p>{course.scholarship}</p>
                    </div>
                  </div>
                  {/* Specialisation */}
                  <div className="  mb-2 text-sm font-[600] flex md:gap-4 tracking-tight flex-wrap md:flex-nowrap">
                    <div className="flex items-center text-sm text-green-600 2 gap-2  flex-wrap md:flex-nowrap">
                      <div className="font-[600] text-black flex flex-wrap w-[]">
                        <span>Specialisation-</span>
                        <span className="flex flex-wrap">
                          {course.specialisation.map((spec, i) => (
                            <span className="  text-sm gap-1  ">
                              <p key={i} className="truncate text-green-500">
                                {spec + ""},{" "}
                              </p>
                            </span>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* Location */}
                  <div className="mb-2 text-sm font-[600] flex gap-4 tracking-tight">
                    <div className="">{course.location}</div>
                    {course.approval && (
                      <div className="flex items-center text-sm text-green-600 2">
                        <TiTick />
                        <p>Approved by UGC AICTE</p>
                      </div>
                    )}
                  </div>
                  <p className="mb-3 text-sm text-left md:text-left font-normal text-gray-500">
                    {course.description}
                  </p>
                  <Link to="/enquiryform">
                    <button
                      className="px-2.5 py-1.5 w-[150px] bg-orange-600 rounded text-[600] font-bold "
                      onClick={handleClick} // Scroll function ko onClick event se trigger karein
                    >
                      <AnimatedText />
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="mt-[3%] mr-4 hidden md:block">
              <h2 className="text-sm font-bold">{course.fee}</h2>
            </div>
          </div>
        ))}
        <div>
          {datanum === coursesdata?.length || alldata.length <= 3 ? (
            ""
          ) : (
            <button
              onClick={increNum}
              type="button"
              className="text-white bg-[#C33] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300
               font-medium rounded-lg text-sm px-12 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700
                focus:outline-none dark:focus:ring-blue-800">
              View All
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Courses;

// import React from "react";
// // import uniImg from "./demoPageImg/uni.png";
// import { TiTick } from "react-icons/ti";
// const Courses = () => {
//   courseData =[
//     {
//       courseName:"B.tech",
//       courseDetails=[{
//         duration:"3year",

//       }]
//     }
//   ]
//   return (
//     <div className=" mx-auto flex flex-col p-2 mt-[8rem]  items-center bg-white border gray-200 rounded-lg shadow md:flex-row md:w-[70%] ">
//       <div className="object-cover  rounded-t-lg w-28 md:w-48 lg:w-48 md:rounded-none md:rounded-s-lg">
//         <img src="" alt="" />
//       </div>
//       <div className="w-[100%] 4 flex flex-col md:justify-start md:items-start justify-center items-center p-4 leading-normal">
//          <div className="2 w-full">
//           <div className="flex justify-between w-[100%]">
//             <h5 className="mb-2  text-[22px] md:text-2xl font-bold tracking-tight">
//               Quantum University Jaipur
//             </h5>
//             <h2 className="text-[16px] font-bold ">
//             ₹ 67,000<span className="text-sm ">/year</span>
//             </h2>
//           </div>
//           <div className="mb-2 2  text-sm font-[600] flex gap-4 tracking-tight">
//             <div className="">Course Details</div>
//             <div className="flex items-center text-sm text-green-600 2 gap-2">

//               <p>3 Year</p>
//               <p>,</p>
//               <p>Oncampus</p>
//               <p>,</p>
//               <p>Full Time</p>
//             </div>
//           </div>

// <div className="flex items-center">
//     <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
//         <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
//     </svg>
//     <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
//         <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
//     </svg>
//     <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
//         <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
//     </svg>
//     <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
//         <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
//     </svg>
//     <svg className="w-4 h-4 text-gray-300 me-1 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
//         <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
//     </svg>
//     <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">4.95</p>
//     <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">out of</p>
//     <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">5</p>
// </div>
// <div className="mb-2 2  text-sm font-[600] flex gap-4 tracking-tight">
//             <div className="">Scholarship : </div>
//             <div className="flex items-center text-sm text-green-600 2 gap-2">

//               <p>according to q care results..</p>

//             </div>
//           </div>
//           <div className="mb-2 2  text-sm font-[600] flex gap-4 tracking-tight">
//             <div className="">specialisation :</div>
//             <div className="flex items-center text-sm text-green-600 2 gap-2">

//               <p>Digital Marketing</p>
//               <p>| Human Resourses |</p>
//               <p>| Tourism |</p>
//               <p>| Hosptal Management |</p>
//               <p>| Finance |</p>

//             </div>
//           </div>

//           <div className="mb-2 2  text-sm font-[600] flex gap-4 tracking-tight">
//             <div className="">Gandhi Nagar, Jaipur</div>
//             <div className="flex items-center text-sm text-green-600 2">
//               <TiTick />
//               <p>Approved by UGC AICTE</p>
//             </div>
//           </div>
//           <p className="mb-3 text-sm text-center md:text-left font-normal text-gray-500">
//             Here are the biggest enterprise technology acquisitions of 2021 so
//             far, in reverse chronological order.
//           </p>
//           <button className="px-2.5 py-1.5 w-[150px] bg-orange-600 rounded text-[600] ">
//             Apply Now
//           </button>
//          </div>
  
//       </div>
//     </div>
//   );
// };

// export default Courses;
