// email.js configuration

import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';

function FormEstimate() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
 

    if (validateFormData()) {
      try {
        const serviceID = 'service_jmmdjx7'; // Replace with your Email.js service ID
        const templateID = 'template_5lg1qnu'; // Replace with your Email.js template ID
        const publicKey = 'bb8dbYZ5ZF3EaU46f'; // Replace with your Email.js user ID

        console.log('Service ID:', serviceID);
        console.log('Template ID:', templateID);
        console.log('User ID:', publicKey);

        const response = await emailjs.sendForm(serviceID, templateID, event.target, publicKey);

        console.log('Response:', response);

        if (response.status === 200) {
          setFormData({
            name: "",
            email: "",
            mobile: "",
            message: ""
          });

          toast.success('Form submitted successfully!', {});
        } else {
          toast.error('Error submitting form. Please try again later!', {});
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Error submitting form. Please try again later!', {});
      }
    } else {
      toast.error('Please fill all required fields correctly!', {});
    }
  };

  const validateFormData = () => {
    const { name, email, mobile } = formData;
    const mobileRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name && email && mobile && mobile.match(mobileRegex) && email.match(emailRegex)) {
      return true;
    }
    return false;
  };

  return (
    <div className="w-[100%] bg-white flex justify-center items-center rounded-md">
      <div className="w-[100%] p-12 rounded shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Enquiry Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name *"
              className="border border-gray-300 p-2 w-full rounded"
              required
            />
          </div>
          
          <div className="mb-4">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email *"
              className="border border-gray-300 p-2 w-full rounded"
              required
            />
          </div>
          
          <div className="mb-4">
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Phone *"
              className="border border-gray-300 p-2 w-full rounded"
              required
            />
          </div>
          
          <div className="mb-4">
            <input
              type="text"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Course"
              className="border border-gray-300 p-2 w-full rounded"
            />
          </div>
          
          {/* Include a hidden input field for spam protection */}
          <input type="text" name="_gotcha" style={{ display: "none" }} />

          {/* <button type="submit" className= {` ${uni.Color} hover:bg-blue-600 text-white py-2 px-4 rounded`}>
            Submit
          </button> */}
          <button type="submit" className= "bg-[#FF0000] hover:bg-blue-600 text-white py-2 px-4 rounded">
            Submit
          </button>
 
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default FormEstimate;

// end email configuration


// import React, { useState } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function FormEstimate({uni}) {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//     message: ""
//   });

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (validateFormData()) {
//       try {
//         // Your Formspree endpoint
//         const endpoint = 'https://formspree.io/f/mayravar';

//         // Send form data to Formspree
//         const response = await fetch(endpoint, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify(formData)
//         });

//         if (response.ok) {
//           // Reset form data after successful submission
//           setFormData({
//             name: "",
//             email: "",
//             mobile: "",
//             message: ""
//           });

//           // Display success toast notification
//           toast.success('Form submitted successfully!', {});
//         } else {
//           // Display error toast if submission fails
//           toast.error('Error submitting form. Please try again later!', {});
//         }
//       } catch (error) {
//         console.error('Error submitting form:', error);
//         // Display error toast if submission fails
//         toast.error('Error submitting form. Please try again later!', {});
//       }
//     } else {
//       // Display error toast if required fields are not filled correctly
//       toast.error('Please fill all required fields correctly!', {});
//     }
//   };

//   const validateFormData = () => {
//     const { name, email, mobile } = formData;
//     const mobileRegex = /^[0-9]{10}$/;
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//     if (name && email && mobile && mobile.match(mobileRegex) && email.match(emailRegex)) {
//       return true;
//     }
//     return false;
//   };

//   return (
//     <div className="w-[100%]   bg-white flex justify-center items-center rounded-md">
//       <div className="w-[100%] p-12 rounded shadow-lg">
//         <h2 className="text-2xl font-bold mb-4">Enquiry Form</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="mb-4">
//             <input
//               type="text"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               placeholder="Name *"
//               className="border border-gray-300 p-2 w-full rounded"
//               required
//             />
//           </div>
          
//           <div className="mb-4">
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               placeholder="Email *"
//               className="border border-gray-300 p-2 w-full rounded"
//               required
//             />
//           </div>
          
//           <div className="mb-4">
//             <input
//               type="tel"
//               name="mobile"
//               value={formData.mobile}
//               onChange={handleChange}
//               placeholder="Phone *"
//               className="border border-gray-300 p-2 w-full rounded"
//               required
//             />
//           </div>
          
//           <div className="mb-4">
//             <input
//               type="text"
//               name="message"
//               value={formData.message}
//               onChange={handleChange}
//               placeholder="Course"
//               className="border border-gray-300 p-2 w-full rounded"
//             />
//           </div>
          
//           {/* Include a hidden input field for spam protection */}
//           <input type="text" name="_gotcha" style={{ display: "none" }} />

//           <button type="submit" className= {` ${uni.Color} hover:bg-blue-600 text-white py-2 px-4 rounded`}>
//             Submit
//           </button>
//         </form>
//       </div>
//       <ToastContainer />
//     </div>
//   );
// }

// export default FormEstimate;






// import React, { useState } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './index.css';

// function FormEstimate() {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//     message: ""
//   });

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (validateFormData()) {
//       try {
//         // Your Formspree endpoint
//         const endpoint = 'https://formspree.io/f/xdoqdbvo';

//         // Send form data to Formspree
//         const response = await fetch(endpoint, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify(formData)
//         });

//         if (response.ok) {
//           // Reset form data after successful submission
//           setFormData({
//             name: "",
//             email: "",
//             mobile: "",
//             message: ""
//           });

//           // Display success toast notification
//           toast.success('Form submitted successfully!', {});
//         } else {
//           // Display error toast if submission fails
//           toast.error('Error submitting form. Please try again later!', {});
//         }
//       } catch (error) {
//         console.error('Error submitting form:', error);
//         // Display error toast if submission fails
//         toast.error('Error submitting form. Please try again later!', {});
//       }
//     } else {
//       // Display error toast if required fields are not filled correctly
//       toast.error('Please fill all required fields correctly!', {});
//     }
//   };

//   const validateFormData = () => {
//     const { name, email, mobile } = formData;
//     const mobileRegex = /^[0-9]{10}$/;
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//     if (name && email && mobile && mobile.match(mobileRegex) && email.match(emailRegex)) {
//       return true;
//     }
//     return false;
//   };

//   return (
//     <>
//       <div className="login-box right_posterUp">
//         <h2>Enquiry Form</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="user-box">
//             <input
//               type="text"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               required
//             />
//             <label>Name *</label>
//           </div>
          
//           <div className="user-box">
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//             <label>Email *</label>
//           </div>
//           <div className="user-box">
//             <input
//               type="tel"
//               name="mobile"
//               value={formData.mobile}
//               onChange={handleChange}
//               required
//             />
//             <label>Mobile Number *</label>
//           </div>
//           <div className="user-box">
//             <input
//               type="text"
//               name="message"
//               value={formData.message}
//               onChange={handleChange}
//             />
//             <label >Message</label>
//           </div>
//           {/* Include a hidden input field for spam protection */}
//           <input type="text" name="_gotcha" style={{ display: "none" }} />

//           <div className="">
//             <button type="submit" className="loginButton">
//               <a><span></span></a>
//               <a><span></span></a>
//               <a><span></span></a>
//               <a><span></span></a>
//               Submit
//             </button>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// }

// export default FormEstimate;



// // // forms pre se pahle 
// // import React, { useState } from "react";
// // import { useForm, ValidationError } from '@formspree/react';
// // import { ToastContainer, toast } from 'react-toastify';
// // import 'react-toastify/dist/ReactToastify.css';
// // import './index.css';

// // function FormEstimate() {
// //   const [formData, setFormData] = useState({
// //     name: "",
// //     email: "",
// //     mobile: "",
// //     message: ""
// //   });

// //   const handleChange = (event) => {
// //     const { name, value } = event.target;
// //     setFormData({ ...formData, [name]: value });
// //   };

// //   const handleSubmit = (event) => {
// //     event.preventDefault();

// //     // Validate form data
// //     if (validateFormData()) {
// //       // Your form submission logic here

// //       // Example: Assume the form submission is successful
// //       // Display a success toast notification
// //       if (toast && toast.success) {
// //         toast.success('Form submitted successfully!', {});
// //       }

// //       // Clear form data after successful submission
// //       setFormData({
// //         name: "",
// //         email: "",
// //         mobile: "",
// //         message: ""
// //       });
// //     } else {
// //       // Display error toast if required fields are not filled correctly
// //       if (toast && toast.error) {
// //         toast.error('Please fill all required fields correctly!', {});
// //       }
// //     }
// //   };

// //   const validateFormData = () => {
// //     const { name, email, mobile } = formData;

// //     // Regular expression to match a 10-digit phone number
// //     const mobileRegex = /^[0-9]{10}$/;

// //     // Regular expression to validate an email address
// //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// //     // Check if all fields are filled and valid
// //     if (name && email && mobile && mobile.match(mobileRegex) && email.match(emailRegex)) {
// //       return true;
// //     }
// //     return false;
// //   };

// //   return (
// //     <>
// //       <div className="login-box right_posterUp">
// //         <h2>Enquiry Form</h2>
// //         <form onSubmit={handleSubmit}>
// //           <div className="user-box">
// //             <input
// //               type="text"
// //               name="name"
// //               value={formData.name}
// //               onChange={handleChange}
// //               required
// //             />
// //             <label>Name *</label>
// //           </div>
          
// //           <div className="user-box">
// //             <input
// //               type="email"
// //               name="email"
// //               value={formData.email}
// //               onChange={handleChange}
// //               required
// //             />
// //             <label>Email *</label>
// //           </div>
// //           <div className="user-box">
// //             <input
// //               type="tel"
// //               name="mobile"
// //               value={formData.mobile}
// //               onChange={handleChange}
// //               required
// //             />
// //             <label>Mobile Number *</label>
// //           </div>
// //           <div className="user-box">
// //             <input
// //               type="text"
// //               name="message"
// //               value={formData.message}
// //               onChange={handleChange}
// //             />
// //             <label >Message</label>
// //           </div>
// //           <div className="">
// //             <button type="submit" className="loginButton">
// //               <a><span></span></a>
// //               <a><span></span></a>
// //               <a><span></span></a>
// //               <a><span></span></a>
// //               Submit
// //             </button>
// //           </div>
// //         </form>
// //       </div>
// //     </>
// //   );
// // }

// // export default FormEstimate;
